<template>
  <v-dialog
    scrollable
    persistent
    v-model="show"
    max-width="750px"
    content-class="rounded-xl"
  >
    <v-overlay class="justify-center align-center" v-if="loading">
      <v-progress-circular
        :size="100"
        :width="8"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex justify-space-between">
        <div class="text-h6">Upgrade Subscription Plan</div>
        <v-btn icon @click="modalClosed">
          <v-icon size="26">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />

      <v-card-text class="pa-6" v-if="item">
        <v-row v-if="stepper === 1">
          <v-col cols="4" v-for="plan in plan_list" :key="plan.id">
            <v-sheet outlined rounded="lg" color="primary">
              <v-sheet outlined rounded="lg" class="pa-2">
                <div
                  class="font-weight-bold d-flex justify-space-between align-center"
                >
                  <span class="">{{ plan.name }}</span>
                  <v-chip small color="primary" v-if="isCurrentPlan(plan)"
                    >Active Plan</v-chip
                  >
                </div>
                <ul class="mt-2">
                  <li
                    v-for="feature in plan.features"
                    :key="`feature-${plan.id}-${feature}`"
                  >
                    {{ feature }}
                  </li>
                </ul>
                <div
                  class="mt-4 d-flex justify-space-between align-center"
                  v-if="showUpgradeBtn(plan)"
                >
                  <div class="font-weight-bold">₹ {{ plan.price }}</div>
                  <v-btn small @click="onPlanSelected(plan)">Upgrade</v-btn>
                </div>
              </v-sheet>
            </v-sheet>
          </v-col>
        </v-row>

        <div v-if="stepper === 2">
          <v-sheet outlined rounded="lg" color="primary">
            <v-sheet outlined rounded="lg" class="pa-2">
              <div class="font-weight-bold">
                Upgrade to {{ selected_plan.name }}
              </div>
              <div>{{ selected_plan.description }}</div>
            </v-sheet>
          </v-sheet>

          <v-form class="mt-6" lazy-validation ref="form">
            <v-text-field
              v-model="form.amount"
              :rules="[$rules.required]"
              height="42px"
              type="number"
              outlined
              dense
              hide-spin-buttons
              label="Amount"
              class="rounded-lg"
            ></v-text-field>

            <v-textarea
              rows="2"
              type="text"
              v-model="form.remarks"
              flat
              outlined
              dense
              hide-details
              label="Remarks (Optional)"
              class="rounded-lg"
            ></v-textarea>

            <div class="mt-6">
              <div>Attach Document (Optional)</div>
              <div class="file-input-container mt-2">
                <v-file-input
                  accept="image/*, application/pdf"
                  id="file-input"
                  v-show="false"
                  @change="onFileChange"
                ></v-file-input>
                <label for="file-input">
                  <div class="file-input d-flex align-center justify-center">
                    <div v-if="form.file">
                      <v-img
                        :src="form.previewFile"
                        height="120"
                        width="120"
                        v-if="form.file.type.startsWith('image/')"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                      <v-icon v-else size="50">mdi-file</v-icon>
                    </div>
                    <v-icon v-else size="40">mdi-upload</v-icon>
                  </div>
                </label>
                <v-icon
                  size="20"
                  v-if="form.file"
                  color="primary"
                  @click="removeFile"
                  class="file-remover"
                  >mdi-close-circle</v-icon
                >
              </div>
            </div>
          </v-form>
        </div>
      </v-card-text>

      <v-divider />

      <v-card-actions class="mx-4 my-2" v-if="stepper === 2">
        <v-spacer />
        <v-btn
          outlined
          height="42px"
          color="primary"
          @click="submit"
          :loading="submitBtnLoading"
          class="text-capitalize rounded-lg"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      stepper: 1,
      loading: false,
      submitBtnLoading: false,
      plan_list: [],
      selected_plan: null,
      form: {
        amount: "",
        remarks: "",
        file: null,
        previewFile: null,
      },
    };
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },

  computed: {
    ...mapGetters({
      dialog: "dialogs/upgradePlanDialog",
    }),

    show: {
      get() {
        return this.dialog.show;
      },
      set() {},
    },
    item() {
      return this.dialog.item;
    },
  },

  methods: {
    ...mapActions({
      showSnackbar: "app/showSnackbar",
      toggleDialog: "dialogs/toggleUpgradePlanDialog",
    }),

    openModal() {
      this.getPlanList();
      this.stepper = 1;
      this.selected_plan = null;
      this.form = {
        amount: "",
        remarks: "",
        file: null,
        previewFile: null,
      };
    },

    modalClosed() {
      this.toggleDialog({ show: false });
    },

    isCurrentPlan(plan) {
      return this.item.plan.id === plan.id;
    },

    showUpgradeBtn(plan) {
      return (
        plan.price > 0 && this.item.plan != null && this.item.plan.id != plan.id
      );
    },

    onPlanSelected(plan) {
      this.selected_plan = plan;
      this.form.amount = plan.price;
      this.stepper = 2;
    },

    onFileChange(file) {
      if (file) {
        this.form.file = file;
        if (file.type.startsWith("image/")) {
          this.form.previewFile = URL.createObjectURL(file);
        }
        URL.revokeObjectURL(file);
      } else {
        this.form.previewFile = null;
      }
    },

    removeFile() {
      this.form.file = null;
      this.form.previewFile = null;
    },

    getPlanList() {
      this.loading = true;

      const onSuccess = (res) => {
        this.plan_list = res.data.data;
      };

      const onFinally = () => {
        this.loading = false;
      };

      return this.$request(
        this.$keys.GET,
        this.$urls.client.membership.planList,
        {
          onSuccess,
          onFinally,
        }
      );
    },

    submit() {
      if (this.$refs.form.validate()) {
        this.submitBtnLoading = true;

        const onSuccess = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.modalClosed();
          this.$emit("submitted");
        };

        const onFinally = () => {
          this.submitBtnLoading = false;
        };

        const data = new FormData();
        data.append("client", this.item.id);
        data.append("plan", this.selected_plan.id);
        data.append("amount", this.form.amount);
        data.append("remarks", this.form.remarks);

        if (this.form.file) {
          data.append("document", this.form.file);
        }

        return this.$request(
          this.$keys.POST,
          this.$urls.client.membership.upgradePlan,
          {
            data,
            onSuccess,
            onFinally,
          }
        );
      }
    },
  },
};
</script>

<style scoped>
.file-input-container {
  position: relative;
  border: 1px dashed #9e9e9e;
  box-sizing: border-box;
  border-radius: 2px;
  width: 130px;
  height: 130px;
}

.file-input {
  height: 100%;
  cursor: pointer;
}

.file-remover {
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}
</style>