<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <!-- start -->
      <div class="d-flex align-center" style="gap: 10px">
        <v-btn icon @click="goBack">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <div class="text-h6">Client Details</div>
      </div>
      <!-- end -->
      <div class="d-flex" style="gap: 12px">
        <v-btn
          color="primary"
          outlined
          class="text-capitalize rounded-lg"
          @click="
            LeadsModal({
              show: true,
              id: client.id,
              type: $keys.EDIT,
            })
          "
        >
          <v-icon size="20" left>mdi-pencil-outline</v-icon>
          Edit
        </v-btn>
        <v-btn
          color="primary"
          outlined
          class="text-capitalize rounded-lg"
          @click="toggleConfirmationDialog({ show: true, action: 'delete' })"
        >
          <v-icon size="20" left>mdi-trash-can-outline</v-icon>
          delete
        </v-btn>
      </div>
    </div>

    <v-card class="mt-6" :outlined="$vuetify.theme.dark">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h6">Basic Information</div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="text-center pb-11" v-if="loading">
          <v-progress-circular
            :size="60"
            :width="2"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else>
          <div class="d-flex flex-wrap" v-if="client" style="gap: 10px 0px">
            <v-sheet width="25%">
              <div class="text--secondary">Name</div>
              <div class="text-capitalize">
                {{ client.name }}
              </div>
            </v-sheet>

            <v-sheet width="25%">
              <div class="text--secondary">Mobile</div>
              <div class="text-capitalize">
                <div v-if="client.user.mobile">
                  {{ client.user.mobile_display }}
                </div>
                <v-icon v-else>mdi-minus</v-icon>
              </div>
            </v-sheet>

            <v-sheet width="25%">
              <div class="text--secondary">Email</div>
              <div class="">
                <div v-if="client.user.email">
                  {{ client.user.email }}
                </div>
                <v-icon v-else>mdi-minus</v-icon>
              </div>
            </v-sheet>

            <v-sheet width="25%">
              <div class="text--secondary">Alternate Email</div>
              <div class="">
                <div v-if="client.user.alternative_email">
                  {{ client.user.alternative_email }}
                </div>
                <v-icon v-else>mdi-minus</v-icon>
              </div>
            </v-sheet>

            <v-sheet width="25%">
              <div class="text--secondary">Address</div>
              <div class="text-capitalize">
                <div v-if="client.full_address">
                  {{ client.full_address }}
                </div>
                <v-icon v-else>mdi-minus</v-icon>
              </div>
            </v-sheet>

            <v-sheet width="25%">
              <div class="text--secondary">Remarks</div>
              <div class="text-capitalize">
                <div v-if="client.remarks">
                  {{ client.remarks }}
                </div>
                <v-icon v-else>mdi-minus</v-icon>
              </div>
            </v-sheet>

            <v-sheet width="25%" v-if="client.irrelevance_reason">
              <div class="text--secondary">Reason of Irrelevance</div>
              <div class="text-capitalize">
                {{ client.irrelevance_reason }}
              </div>
            </v-sheet>

            <v-sheet width="25%">
              <div class="text--secondary">Subscription Plan</div>
              <div class="text-capitalize">
                <div v-if="client.plan">
                  {{ client.plan.name }}
                  <span
                    v-if="client.plan.upgradable"
                    class="ml-2 text-decoration-underline primary--text pointer"
                    @click="
                      toggleUpgradePlanDialog({ show: true, item: client })
                    "
                    >Upgrade</span
                  >
                </div>
                <v-icon v-else>mdi-minus</v-icon>
              </div>
            </v-sheet>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="my-8" :outlined="$vuetify.theme.dark">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h6">Case List</div>
        <v-btn
          color="primary"
          outlined
          class="text-capitalize rounded-lg"
          @click="addCase"
          small
        >
          <v-icon left size="18">mdi-plus</v-icon>
          Add Case
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <v-data-table
          :loading="caseLoading"
          loading-text="Please wait ....Loading"
          :headers="case_detail_header"
          :items="caseList"
          mobile-breakpoint
          hide-default-footer
        >
          <template v-slot:[`item.date`]="{ item }">
            {{ $utils.formatDate(item.modified) }}
          </template>

          <template v-slot:[`item.total`]="{ item }">
            ₹{{ item.total_amount }}
          </template>

          <template v-slot:[`item.university`]="{ item }">
            <div v-if="item.university">
              <div>
                {{ item.university.name }}
              </div>
              <div v-if="item.university.full_address">
                {{ item.university.full_address }}
              </div>
            </div>
            <div v-else>
              <v-icon size="20">mdi-minus</v-icon>
            </div>
          </template>

          <template v-slot:[`item.service`]="{ item }">
            <div v-if="item.service">
              {{ item.service.name }}
            </div>
            <div v-else>
              <v-icon size="20">mdi-minus</v-icon>
            </div>
          </template>

          <template v-slot:[`item.eca`]="{ item }">
            <div v-if="item.eca.length" class="text-left">
              <li v-for="eca in item.eca" :key="`eca-${eca.id}`">
                {{ eca.name }}
              </li>
            </div>
            <v-icon v-else>mdi-minus</v-icon>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip color="primary" outlined small>
              {{ item.status_display }}
            </v-chip>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              small
              outlined
              color="primary"
              class="text-capitalize rounded-lg"
              @click="
                $router.push({
                  name: 'caseDetail',
                  params: {
                    id: item.id,
                  },
                })
              "
            >
              View Case
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-card class="mt-5" :outlined="$vuetify.theme.dark">
      <v-card-title class="d-flex justify-space-between align-center">
        <div class="text-h6">Payment Records</div>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <v-data-table
          :loading="paymentLoading"
          loading-text="Please wait ....Loading"
          :headers="paymentRecordHead"
          :items="paymentList"
          hide-default-footer
          mobile-breakpoint
        >
          <template v-slot:[`item.payment_date`]="{ item }">
            {{ $utils.formatDate(item.payment_date) }}
          </template>

          <template v-slot:[`item.case`]="{ item }">
            <div v-if="item.case.case_id">
              {{ item.case.case_id }}
            </div>
          </template>

          <template v-slot:[`item.client`]="{ item }">
            <div v-if="item.case.client">
              {{ item.case.client.name }}
            </div>
          </template>

          <template v-slot:[`item.mode`]="{ item }">
            <div v-if="item.mode">
              {{ item.mode.name }}
            </div>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <div v-if="item.amount">₹{{ item.amount }}</div>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              small
              class="text-capitalize lighten-5 text--darken-2 font-weight-bold"
              :class="
                item.status == 'successful'
                  ? 'success success--text'
                  : item.status == 'pending'
                  ? 'blue blue--text'
                  : 'error error--text'
              "
            >
              {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:[`item.remarks`]="{ item }">
            <div v-if="item.remarks">
              {{ item.remarks }}
            </div>
            <v-icon v-else>mdi-minus</v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <ConfirmationDialog @confirmed="deleteClient" height="130px" width="130px">
      <template v-slot:confirmBtnText> Yes Delete </template>
    </ConfirmationDialog>
    <add-leads-modal />
    <upgrade-plan-dialog @submitted="reloadPage" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import AddLeadsModal from "./addLeadsModal.vue";
import header from "@/common/clientManagementHeader/ClientManagementHeader";
import paymentRecordHeader from "@/common/TableHeaders/ClientDetail/paymentrecord";

import ConfirmationDialog from "@/components/Dialogs/ConfirmationDialog";
import UpgradePlanDialog from "@/components/Dialogs/UpgradePlanDialog";

export default {
  components: { ConfirmationDialog, UpgradePlanDialog, AddLeadsModal },
  data() {
    return {
      client: null,
      loading: false,

      caseList: [],
      caseLoading: false,

      paymentList: [],
      paymentLoading: false,

      paymentRecordHead: [],
      case_detail_header: header.case_detail,
    };
  },
  computed: {
    ...mapGetters({}),
  },

  methods: {
    ...mapActions({
      toggleConfirmationDialog: "dialogs/toggleConfirmationDialog",
      setConfirmationDialogBtnLoading:
        "dialogs/setConfirmationDialogBtnLoading",
      LeadsModal: "clientManagement/LeadsModal",
      showSnackbar: "app/showSnackbar",
      toggleUpgradePlanDialog: "dialogs/toggleUpgradePlanDialog",
    }),

    getCaseList() {
      this.caseLoading = true;

      const onSuccess = (res) => {
        this.caseList = res.data.data;
      };

      const onFinally = () => {
        this.caseLoading = false;
      };

      let params = {};
      params["client_id"] = this.client.id;

      return this.$request(this.$keys.GET, this.$urls.case.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    deleteClient() {
      let params = {
        client_id: this.client.id,
      };

      const onSuccess = (res) => {
        this.toggleConfirmationDialog({ show: false });
        this.showSnackbar({
          text: res.data.message,
          color: "success",
        });
        this.$router.push({ name: "clientManagement" });
      };

      const onFinally = () => {
        this.setConfirmationDialogBtnLoading({ loading: false });
      };

      this.$request(this.$keys.DELETE, this.$urls.client.delete, {
        params,
        onSuccess,
        onFinally,
      });
    },

    getClientDetail() {
      const params = {
        client_id: this.$route.params.id,
      };

      const successHandler = (res) => {
        this.client = res.data.data;
      };

      return this.$request(this.$keys.GET, this.$urls.client.detail, {
        params: params,
        onSuccess: successHandler,
      });
    },

    getPaymentList() {
      this.paymentLoading = true;

      const onSuccess = (res) => {
        this.paymentList = res.data.data;
      };

      const onFinally = () => {
        this.paymentLoading = false;
      };

      let params = {};
      params["client_id"] = this.client.id;

      return this.$request(this.$keys.GET, this.$urls.case.payment.list, {
        params,
        onSuccess,
        onFinally,
      });
    },

    goBack() {
      this.$router.go(-1);
    },

    async reloadPage() {
      this.loading = true;
      await this.getClientDetail();
      this.loading = false;
    },

    addCase() {
      this.$router.push({
        name: "caseAdd",
        query: { client: this.$route.params.id },
      });
    },
  },

  async mounted() {
    this.loading = true;
    this.paymentRecordHead = paymentRecordHeader.header;
    await this.getClientDetail();
    if (this.client) {
      await this.getCaseList();
      await this.getPaymentList();
    }
    this.loading = false;
  },
};
</script>
<style scoped>
.title-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: -0.01em;
}

.card-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
}
.client-info-tag {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  opacity: 0.6;
}
.client-info {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
</style>
